.expander {
  $self: &;
  border-top: solid 1px $color--grey-green;

  &__header {
    display: flex;
    align-items: center;
    padding: 8px 0 20px;
    cursor: pointer;
    justify-content: space-between;
    transition: padding-bottom 0.3s ease-in-out, min-height 0.3s ease-in-out;

    @include breakpoint-s {
      padding-bottom: 29px;
      min-height: 70px;
    }

    &-icons {
      align-self: flex-start;
      margin-left: auto;
      margin-top: 2px;
    }

    &--has-questionmark {
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(10deg);
        }

        100% {
          transform: rotate(-10deg);
        }
      }
      &:hover #{$self}__button {
        animation: rotate 0.5s infinite;
        background-color: $color--yellow-strong;
      }
    }
  }

  #{$self}__title {
    margin: 0;
    flex-grow: 1;
    line-height: 1.2;
    padding-right: $spacing--s;
    color: $color--green-dark;
    @include responsive-font-size(16px, 25px);
    line-height: 1.25;
    font-weight: 500;
    transition: margin-bottom 0.5s linear;

    @include breakpoint-s {
      line-height: 1.32;
      font-weight: 300;
      max-width: 738px;
    }

    &--no-content {
      cursor: default;
    }
  }
  &--with-icons &__title {
    word-break: break-word;
    padding-right: 10px;
  }

  &--in-membership-list #{$self}__button {
    &:before {
      display: none;
    }
  }

  &__questionmark {
    margin-top: 3px;
  }

  &__button {
    &--has-questionmark {
      &:before {
        display: none !important;
      }
      margin-top: 3px;
      font-size: 16px;
      font-weight: 500;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $color--beige-dark;

      @include breakpoint-s {
        font-size: 18px;
        width: 30px;
        height: 30px;
      }
    }

    appearance: none;
    background-color: transparent;
    border: none;
    flex-shrink: 0;
    padding: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    align-self: flex-start;
    margin-top: 6px;
    margin-left: auto;

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border: 2px solid $color--green-dark;
      transform: rotate(45deg);
      border-top: none;
      border-left: none;
      transition: all 0.3s linear;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -14px;

      @include breakpoint-s {
        width: 15px;
        height: 15px;
        margin-left: -8px;
        margin-top: -14px;
      }
    }

    &--active:before {
      transform: rotate(-135deg);
      margin-top: -9px;

      @include breakpoint-s {
        margin-top: -7px;
      }
    }

    #{$self}--with-icons & {
      margin-left: 10px;

      @include breakpoint-m {
        margin-left: 40px;
      }
    }
  }

  &__content {
    padding-right: 0;

    &-wrapper {
      overflow: hidden;
      width: 100%;
    }
  }
}
