.expander-list {
  &--narrow {
    @include breakpoint-m {
      max-width: 50%;
    }
  }

  &__title {
    padding-bottom: $spacing--s;
    @include breakpoint-s {
      padding-bottom: $spacing--m;
    }
  }

  &__item-text {
    padding-bottom: 10px;

    > p {
      margin: 0;
      line-height: 1.83;
    }
  }
}
