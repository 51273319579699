.contact-list {
  &__contacts {
    margin-top: $spacing--m;

    @include breakpoint-s {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__contact {
    margin-bottom: $spacing--l;

    @include breakpoint-s {
      width: 45%;
    }

    @include breakpoint-m {
      width: 28%;

      &:nth-child(3n + 1),
      &:nth-child(3n + 2) {
        margin-right: 8%;
      }

      &:last-child {
        margin-right: auto;
      }
    }
  }
}
