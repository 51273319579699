$desktop-margin: calc((100vw - 100% - 8px) / 2 * -1);

.image-with-text-list-wrapper {
  background-color: $color--beige;
  padding: 0;

  .content-wrapper & {
    margin-left: -$spacing--s;
    margin-right: -$spacing--s;
    width: calc(100% + #{$spacing--s * 2});

    @include breakpoint-s {
      margin-left: -$spacing--l;
      margin-right: -$spacing--l;
      width: calc(100% + #{$spacing--l * 2});
    }

    @include breakpoint-m {
      margin-left: $desktop-margin;
      margin-right: $desktop-margin;
      width: calc(100% + #{$desktop-margin}* 2 * -1);
      max-width: 100vw;
    }
  }
}

.image-with-text-list {
  @include content-wrapper-padding;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: $spacing--m;
  padding-bottom: $spacing--m;
  gap: $spacing--sm;
  max-width: 1360px;
  margin: auto;

  @include breakpoint-ms {
    padding-top: $spacing--xxl;
    padding-bottom: $spacing--xxl;
  }
}
