.contact-card {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;

  &--small-text {
    .contact-card__title,
    .contact-card__job-title {
      @include responsive-font-size(20px, 25px);
    }

    .contact-card__job-title {
      font-weight: 200;
      font-family: $font-family;
      text-transform: none;
      color: $color--black;
      margin: 0;
      letter-spacing: 0;
    }
  }

  &--vertical-mobile {
    @media (min-width: 375px) and (max-width: $breakpoint-s - 1px),
      (min-width: 600px) and (max-width: $breakpoint-m - 1px) {
      flex-direction: row-reverse;

      .contact-card__image {
        width: 40%;
        padding-top: 40%;
      }

      .contact-card__text {
        width: 60%;
        padding-left: 5%;
      }

      .contact-card__header {
        margin-top: 0;
      }
    }
  }

  &__header {
    margin-top: $spacing--sm;
    display: flex;
    flex-direction: column-reverse;
  }

  &__image {
    position: relative;
    width: 60%;
    padding-top: 60%;

    @include breakpoint-m {
      width: 80%;
      padding-top: 80%;
    }
  }

  &__job-title {
    @include responsive-font-size(13px, 16px);
    font-family: $font-family-secondary;
    color: $color--slate;
    margin: 0 0 0.3rem;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
  }
}
