.inspo-animated-title {
  width: 100%;

  &__title-container {
  }

  &__title-background {
    padding: 5px;
    font-weight: 200;

    @include breakpoint-sm {
      padding: 25px 0px 15px 0px;
    }

    &--pink {
      @include title-gradient(0, $color--pink, $color--pink);
    }

    &--green {
      @include title-gradient(0, $color--green-dark, $color--green-dark);
    }

    &--white {
      @include title-gradient(0, white, white);
    }

    &--light-pink {
      @include title-gradient(0, $color--pink-light, $color--pink-light);
    }

    &--transparent {
      @include title-gradient(0, transparent, transparent);
    }
  }

  &__title-folder {
    padding: 5px;

    @include breakpoint-sm {
      padding: 25px 15px 15px 15px;
    }

    &--pink {
      @include title-gradient(90deg, transparent 90%, $color--pink-light 90%);
    }

    &--green {
      @include title-gradient(90deg, transparent 90%, $color--slate 90%);
    }

    &--light-pink {
      @include title-gradient(90deg, transparent 90%, $color--pink 90%);
    }

    &--white {
      @include title-gradient(90deg, transparent 90%, #e7e5df 90%);
    }

    &--is-mounted {
      background-size: 112%;
      transition: background-size 0.9s ease-in-out;
    }

    &--transparent {
      @include breakpoint-sm {
        padding: 25px 15px 15px 0px;
      }
    }
  }

  &__title-text {
    line-height: 1.3;
    font-size: convert-to-rem(30px);
    background: linear-gradient($color--green-dark, $color--green-dark);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 0%;

    @include breakpoint-sm {
      font-size: convert-to-rem(50px);
    }

    &--green,
    &--transparent {
      font-size: convert-to-rem(30px);
      background: linear-gradient(white, white);
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 0%;

      @include breakpoint-sm {
        font-size: convert-to-rem(50px);
      }
    }
  }

  &__title-background,
  &__title-text,
  &__title-folder {
    background-repeat: no-repeat;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
  }

  &__title-background,
  &__title-text {
    &--is-mounted {
      background-size: 100%;
      transition: background-size 0.8s ease-in-out;
    }
  }

  &__tag {
    @include responsive-font-size(14px, 16px);
    width: max-content;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: 'National2Condensed';
    color: $color--green-dark;
    border-radius: 19px;
    background-color: rgba(245, 242, 236, 0.85);
    padding: 5px 15px;
    margin-bottom: $spacing--xs;
    opacity: 0;

    &--is-mounted {
      opacity: 1;
      transition: opacity ease-in 0.4s;
    }

    @include breakpoint-m {
      padding: 10px 15px;
      margin-bottom: $spacing--s;
    }
  }
}
